import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const ErrorPage = () => {
  const handleGoBack = () => {
    window.location.href = '/dashboard/analytics';
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight="100vh"
      textAlign="center"
      padding={3}
      sx={{ backgroundColor: 'background.default' }}
    >
      <Typography variant="h1" gutterBottom color="primary">
        ERROR
      </Typography>
      <Typography variant="h4" gutterBottom color="textPrimary">
        503 Service Unavailable
      </Typography>
      <Typography variant="body1" paragraph sx={{ maxWidth: '600px', mb: 4 }} color="textSecondary">
        Sorry, the service is temporarily unavailable. Please try again later.
        Navigate back to familiar grounds or seek our assistance at support@versaquant.com. We're here to help!
      </Typography>
      <Button
        onClick={handleGoBack}
        variant="contained"
        color="primary"
        size="large"
        sx={{ 
          minWidth: '200px',
          py: 1,
          '&:hover': {
            backgroundColor: 'primary.dark'
          }
        }}
      >
        Go back to home
      </Button>
    </Box>
  );
};

export default ErrorPage;