import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project import
import useAuth from 'sections/apps/profiles/user/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn && location.pathname !== '/') {
      navigate('login', { replace: true });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;
