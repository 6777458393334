import React, {createContext, useEffect, useReducer} from 'react';

// third-party
import {Auth0Client} from '@auth0/auth0-spa-js';

// reducer - state management
import {LOGIN, LOGOUT} from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import {AUTH0_API} from 'config';
import {KeyedObject} from 'types/root';
import {Auth0ContextType, AuthProps, UserProfile} from 'types/auth';

// constant
let auth0Client: Auth0Client;

const initialState: AuthProps = {
    isLoggedIn: false,
    token: "",
    isInitialized: false,
    user: null
};
  
// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext<Auth0ContextType | null>(null);

const getDefaultAvatar = (email: string) => {
    return `https://ui-avatars.com/api/?name=${encodeURIComponent(email)}&background=random`;
};

export const Auth0Provider = ({children}: { children: React.ReactElement }) => {
        const [state, dispatch] = useReducer(authReducer, initialState);

        const login = async (options?: KeyedObject) => {
            try {
                await auth0Client.loginWithRedirect({
                    redirect_uri: window.location.origin,
                    appState: { returnTo: '/dashboard/analytics' }
                });
            } catch (err) {
                console.error('Auth0 login error:', err);
            }
        };

        useEffect(() => {
            const init = async () => {
                try {
                    auth0Client = new Auth0Client({
                        domain: AUTH0_API.domain,
                        client_id: AUTH0_API.client_id,
                        redirect_uri: window.location.origin,
                        audience: AUTH0_API.audience,
                        scope: AUTH0_API.scope,
                        useRefreshTokens: AUTH0_API.useRefreshTokens,
                        cacheLocation: 'localstorage'
                    });

                    // Handle the redirect callback
                    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                        try {
                            const result = await auth0Client.handleRedirectCallback();
                            const targetUrl = result?.appState?.returnTo || '/dashboard/analytics';
                            window.location.href = window.location.origin + targetUrl;
                            return;
                        } catch (callbackError) {
                            console.error('Callback handling error:', callbackError);
                            window.location.href = window.location.origin;
                        }
                    }

                    try {
                        // Handle callback if present
                        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                            await auth0Client.handleRedirectCallback();
                            window.location.replace('http://localhost:3000/login');
                            return;
                        }

                        // Don't check auth on login page or landing page
                        if (window.location.pathname === '/login' || window.location.pathname === '/') {
                            dispatch({ type: LOGOUT });
                            return;
                        }

                        // Check if we're authenticated
                        const isAuthenticated = await auth0Client.isAuthenticated();
                        if (!isAuthenticated) {
                            dispatch({ type: LOGOUT });
                            window.location.replace('http://localhost:3000/login');
                            return;
                        }

                        // Get user info and token
                        try {
                            const [user, token] = await Promise.all([
                                auth0Client.getUser(),
                                auth0Client.getTokenSilently()
                            ]);

                            if (!user || !token) {
                                throw new Error('Missing user info or token');
                            }

                            let namespace = "https://api.versaquant.com";
                            const userProfile: UserProfile = {
                                id: user.sub,
                                email: user.email,
                                name: user.name || user.email?.split('@')[0],
                                app_metadata: user[`${namespace}/app_metadata`] || {},
                                picture: user.picture || getDefaultAvatar(user.email || ''),
                                role: user['https://api.versaquant.com/roles']?.[0] || 'user'
                            };

                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: true,
                                    token,
                                    user: userProfile
                                }
                            });
                        } catch (error) {
                            console.error('Error getting user info:', error);
                            dispatch({ type: LOGOUT });
                            window.location.replace('http://localhost:3000/login');
                        }
                    } catch (sessionError) {
                        console.error('Session check error:', sessionError);
                        dispatch({
                            type: LOGOUT
                        });
                    }
                } catch (err) {
                    console.error('Auth0 initialization error:', err);
                    dispatch({
                        type: LOGOUT
                    });
                }
            };

            init();
        }, []);

        const logout = () => {
            // First clear all local state
            dispatch({ type: LOGOUT });
            localStorage.clear();
            sessionStorage.clear();

            // Clear all cookies with various paths
            const paths = ['/', '/login', '/dashboard'];
            document.cookie.split(';').forEach(cookie => {
                const [name] = cookie.split('=');
                paths.forEach(path => {
                    document.cookie = `${name.trim()}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
                });
            });

            // Construct Auth0 logout URL
            const logoutUrl = new URL(`https://${AUTH0_API.domain}/v2/logout`);
            logoutUrl.searchParams.set('client_id', AUTH0_API.client_id);
            logoutUrl.searchParams.set('returnTo', window.location.origin);
            logoutUrl.searchParams.set('federated', '');

            // Force immediate navigation
            window.location.replace(logoutUrl.toString());

            // Fallback redirect after a short delay
            setTimeout(() => {
                window.location.replace(window.location.origin);
            }, 100);
        };

        const resetPassword = async (email: string) => {
        };

        const updateProfile = () => {
        };


        if (state.isInitialized !== undefined && !state.isInitialized) {
            return <Loader/>;
        }

        return <Auth0Context.Provider
            value={{...state, login, logout, resetPassword, updateProfile}}>{children}</Auth0Context.Provider>;
    }
;

export default Auth0Context;
