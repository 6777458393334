// third-party
import {createSlice} from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import {dispatch} from "../index";

import {Address, DefaultRootStateProps, PortfoliosFilter} from "types/pmp";
import getJobId from "../../hooks/getJobId";
import getApiUrl from "hooks/useApiUrl";

const initialState: DefaultRootStateProps["portfolio"] = {
    error: null,
    jobId: "",
    description: "",
    portfolios: [],
    align: {},
    portfolio: null,
    botConfig: null,
    botConfigGroupingPerf: null,
    customPortfolioStyle: null,
    performanceSummary: null,
    portfolioFundamental: null,
    customPortfolioGrouping: null,
    statsSummary: null,
    portfolioCumulPerf: null,
    privateMarketPlaceMeta: null,
    portfolioGPT: null,
    scores: null,
    benchmarkScores: null,
    pmpAlign:[],
    relatedPortfolios: [],
    reviews: [],
    addresses: [],
    userInfo: []
};

function getTopFrequencies(number: any, frequency: any) {
    let counter = number.reduce(
        (counter: any, key: any) => {
            counter[key] = 1 + counter[key] || 1;
            return counter;
        }, {});
    let frequencyMap = Object.entries(counter).sort((a: any, b: any) => b[1] - a[1]).slice(0, frequency); // choose number of most frequent
    let f: any = [];
    frequencyMap.map((a, b) => {
        f.push(a[0]);
    });
    return f;
}

function getNumbersInPosition(mega: any, number: number) {
    return mega.map((a: any) => {
        return a.winning_numbers.split(" ")[number];
    });
}

const slice = createSlice({
    name: "portfolio",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getPortfoliosSuccess(state, action) {
            state.portfolios = action.payload;
        },
        getDescriptionSuccess(state, action) {
            state.description = action.payload;
        },

        getPerformanceSummarySuccess(state, action) {
            state.performanceSummary = action.payload;
        },
        getStatsSummarySuccess(state, action) {
            state.statsSummary = action.payload;
        },
        getCustomPortfolioCumulPerfSuccess(state, action) {
            state.portfolioCumulPerf = action.payload;
        },
        getPrivateMarketPlaceMetaSuccess(state, action) {
            state.privateMarketPlaceMeta = action.payload;
        },
        getPortfolioFundamentalSuccess(state, action) {
            state.portfolioFundamental = action.payload;
        },
        getPortfolioGPTSuccess(state, action) {
            state.portfolioGPT = action.payload;
        },
        filterPortfoliosSuccess(state, action) {
            state.portfolios = action.payload;
        },

        getPortfolioSuccess(state, action) {
            state.portfolio = action.payload;
        },
        getBotConfigSuccess(state, action) {
            state.botConfig = action.payload;
        },
        getBotConfigGroupingPerfSuccess(state, action) {
            state.botConfigGroupingPerf = action.payload;
        },
        getCustomPortfolioStyleSuccess(state, action) {
            state.customPortfolioStyle = action.payload;
        },
        getCustomPortfolioGroupingSuccess(state, action) {
            state.customPortfolioGrouping = action.payload;
        },

        getScoresSuccess(state, action) {
            state.scores = action.payload;
        },

        getBenchmarkScoresSuccess(state, action) {
            state.benchmarkScores = action.payload;
        },

        getAlignSuccess(state, action) {
            state.align = action.payload;
        },

        getRelatedPortfoliosSuccess(state, action) {
            state.relatedPortfolios = action.payload;
        },

        getPortfolioReviewsSuccess(state, action) {
            state.reviews = action.payload;
        },
       
        getAddressesSuccess(state, action) {
            state.addresses = action.payload;
        },
       
        addAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        editAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        getUserInfoSuccess(state, action) {
            state.userInfo = action.payload
        },
        getPMPSuccess(state,action){
            state.pmpAlign= action.payload
        }
    }
});

export default slice.reducer;

interface TransformedUserData {
    designation: string;
    firstname: string;
    lastname: string;
    email: string;
    dob: string;
    countryCode: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    state: string;
    zip: string;
    country: string;
    note: string;
    stripe_customer_id: string | null;
    stripe_portal_session?: string;
}

export function getCustomPortfolioDescription(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobID = await getJobId(token);
        let url = getApiUrl() + `run3/pc.getCustomPortfolioDescription/["${status}","${portfolioID}","${jobID}"]`;
        try {            
            await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(async response => {
                {
                    response.json().then(async result => {
                        dispatch(slice.actions.getDescriptionSuccess(result));
                    });
                }
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

const extractSourceFromUrl = (): string => {
    const hostname = window.location.hostname;
    if (hostname.includes("sandbox")) {
        return "sandbox";
    } else if (hostname.includes("alpha")) {
        return "alpha";
    } else if (hostname === "localhost") {
        return "local";
    } else {
        return "production";
    }
};

export const getUserInfo = (token: any, onlyUserInfo: boolean = false) => async (dispatch: any) => {
    try {
        const baseUrl = 'https://api.versaquant.com:5001/';
        const userInfoUrl = `${baseUrl}user/getUserInfo/`;
        console.log('Fetching user info from:', userInfoUrl);

        // Fetch user information
        const userResponse = await fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                "accept": "application/json",
            },
        });

        if (!userResponse.ok) {
            const errorText = await userResponse.text();
            console.error('User info response not ok:', userResponse.status, errorText);
            throw new Error(`Failed to fetch user info: ${errorText}`);
        }

        const userData = await userResponse.json();
        console.log('Raw user info data:', userData);

        // Extract Stripe customer ID
        const stripeCustomerId = 
            userData.stripe_customer_id || 
            userData.app_metadata?.data?.object?.customer ||
            userData.app_metadata?.stripe_customer_id ||
            userData.user_metadata?.stripe_customer_id;

        console.log('Stripe Customer ID:', stripeCustomerId);

        // If onlyUserInfo is true, exit early after fetching user info
        if (onlyUserInfo) {
            const transformedData = {
                firstname: userData.firstname || '',
                lastname: userData.lastname || '',
                email: userData.email || '',
                dob: userData.dob || '',
                countryCode: userData.countryCode || '',
                phoneNumber: userData.phoneNumber || '',
                address1: userData.address1 || '',
                address2: userData.address2 || '',
                state: userData.state || '',
                zip: userData.zip || '',
                country: userData.country || 'US',
                note: userData.note || '',
                stripe_customer_id: stripeCustomerId || '',
            };

            dispatch(slice.actions.getUserInfoSuccess(transformedData));
            return; // Exit after dispatching user info only
        }

        // Continue with Stripe logic if onlyUserInfo is false or not provided
        let validStripeCustomerId = stripeCustomerId;

        if (stripeCustomerId) {
            // Validate Stripe customer ID
            const validateUrl = `${baseUrl}validate_customer/${stripeCustomerId}`;
            const validateResponse = await fetch(validateUrl, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "accept": "application/json",
                },
            });

            if (!validateResponse.ok) {
                console.warn('Invalid Stripe Customer ID, will create a new one.');
                validStripeCustomerId = null;
            }
        }

        if (!validStripeCustomerId) {
            // Create new Stripe customer
            const createCustomerUrl = `${baseUrl}create_customer/`;
            const createCustomerResponse = await fetch(createCustomerUrl, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name: `${userData.firstname} ${userData.lastname}` }),
            });

            if (!createCustomerResponse.ok) {
                const errorText = await createCustomerResponse.text();
                console.error('Failed to create new Stripe customer:', errorText);
                throw new Error(`Failed to create new Stripe customer: ${errorText}`);
            }

            const newCustomerData = await createCustomerResponse.json();
            validStripeCustomerId = newCustomerData.id;
            
            console.log('Created new Stripe Customer ID:', validStripeCustomerId);
            
            // Update Auth0 with the new Stripe customer ID
            const updateUserInfoUrl = `${baseUrl}user/updateUserInfo/`;
            const payload = { stripe_customer_id: validStripeCustomerId };

            const updateUserResponse = await fetch(updateUserInfoUrl, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!updateUserResponse.ok) {
                const errorText = await updateUserResponse.text();
                console.error('Failed to update user info in Auth0:', errorText);
                throw new Error(`Failed to update user info: ${errorText}`);
            }

            console.log('Successfully updated Auth0 with new Stripe customer ID.');
        }

        // Extract source from the web URL
        const source = extractSourceFromUrl();

        // Create Stripe portal session
        const portalUrl = `${baseUrl}stripe/create_portal_session/v2/`;
        const portalPayload = { id: validStripeCustomerId, source: source };

        const portalResponse = await fetch(portalUrl, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(portalPayload),
        });

        if (!portalResponse.ok) {
            console.error('Failed to create Stripe portal session');
            throw new Error('Failed to create Stripe portal session');
        }

        const portalData = await portalResponse.json();
        
        const metadata = userData.user_metadata || {};
        const transformedData = {
            designation: metadata.designation || userData.designation || '',
            firstname: metadata.firstname || userData.firstname || '',
            lastname: metadata.lastname || userData.lastname || '',
            email: metadata.email || userData.email || '',
            dob: metadata.dob || userData.dob || '',
            countryCode: metadata.countryCode || userData.countryCode || '',
            phoneNumber: metadata.phoneNumber || userData.phoneNumber || '',
            address1: metadata.address1 || userData.address1 || '',
            address2: metadata.address2 || userData.address2 || '',
            state: metadata.state || userData.state || '',
            zip: metadata.zip || userData.zip || '',
            country: metadata.country || userData.country || 'US',
            note: metadata.note || userData.note || '',
            stripe_customer_id: validStripeCustomerId,
            stripe_portal_session: portalData.url,
        };

        dispatch(slice.actions.getUserInfoSuccess(transformedData));
    } catch (error: unknown) {
        console.error('Error fetching user info:', error);
        const errorMessage = error instanceof Error ? error.message : 'Failed to fetch user information';
        dispatch(slice.actions.hasError(errorMessage));
    }
};


export function getCustomPortfolioAllocations(token: any, portfolioId: any) {
    return async () => {
        try {
            let jobId = await getJobId(token);
            if (!portfolioId) {
                return await jobId && fetch(`${getApiUrl()}run3/pd.getPrivateMarketPlace/["${jobId}"]`, {
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${token}`
                    }
                }).then(response => {
                    return response.json().then(async result => {
                        let url = `${getApiUrl()}run3/pc.getCustomPortfolioAllocations/["pmp", "${result.portList[0].PortfolioID}","${jobId}"]`;
                        return await fetch(url, {
                            mode: "cors",
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Authorization": `Bearer ${token}`
                            }
                        }).then(res => {
                            res.json().then(r => {
                                dispatch(slice.actions.getBotConfigSuccess(r));
                            });
                        });
                    }).catch(e => {
                        dispatch(slice.actions.hasError(e));
                    });
                }).catch(e => {
                    console.log(e);
                });
            } else {
                let url = `${getApiUrl()}run3/pc.getCustomPortfolioAllocations/["pmp", "${portfolioId}","${jobId}"]`;
                return await fetch(url, {
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${token}`
                    }
                }).then(res => {
                    res.json().then(r => {
                        dispatch(slice.actions.getBotConfigSuccess(r));
                    });
                });
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



export function getPerformanceSummary(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `${getApiUrl()}run3/pc.getCustomPortfolioPerformanceStats/["${status}","${portfolioID}","${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(r => {
            r.json().then(res => {
                dispatch(slice.actions.getPerformanceSummarySuccess(res));
            });
        });
    };
}



export function getPortfolioFundamental(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `${getApiUrl()}run3/pc.getCustomPortfolioFundamentals/["${status}","${portfolioID}","${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(res => {
                dispatch(slice.actions.getPortfolioFundamentalSuccess(res));
            });
        });
    };
}


export function getStatsSummary(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `${getApiUrl()}run3/pc.getCustomPortfolioBmkRiskStats/["${status}","${portfolioID}","${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getStatsSummarySuccess(r));
            });
        });
    };
}


export function getCustomPortfolioCumulPerf(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `${getApiUrl()}run3/pc.getCustomPortfolioCumulPerf/["${status}","${portfolioID}","${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getCustomPortfolioCumulPerfSuccess(r));
            });
        }).then((e) => {
            console.log(e);
        });
    };
}


export function addCustomWatchlist(token: any, portfolioID: any, tag: any) {
    return async () => {
        let jobid = portfolioID.split('_')[1];
        let url = `${getApiUrl()}run3/pc.addCustomPortfolioToWatchList/["${portfolioID}","${jobid}","${tag}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                console.log("added to watchlist successfully");
            });
        }).then((e) => {
            console.log(e);
        });
    }
}

export function RTCustomDaily(token: any, portfolioID: any) {
    return async () => {
        let url = `${getApiUrl()}run3/pc.runRTCustomBTDaily/["${portfolioID}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                console.log("updated portfolio successfully");
            });
        }).then((e) => {
            console.log(e);
        });
    }
}



export function getPrivateMarketPlaceMeta(token: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `${getApiUrl()}run3/pd.getPrivateMarketPlaceMeta/["${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getPrivateMarketPlaceMetaSuccess(r));
            });
        });
    };
}


export function getExplainAlign(token: any, input: any) {
    return async () => {
        let url = `${getApiUrl()}run3/pa.explainAlign/["${input}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getPortfolioGPTSuccess(r));
            });
        });
    };
}


export function getPrivateMarketPlace(token: any) {
    return async () => {
        try {
            let jobId = await getJobId(token);
            await jobId && fetch(`${getApiUrl()}run3/pd.getPrivateMarketPlace/["${jobId}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    let portList = result.portList;
                    portList?.map((port: any) => {
                        port.image = "port.png";
                        port.isStock = true;
                    });
                    dispatch(slice.actions.getPortfoliosSuccess(portList));
                });
            }).catch(e => {
                console.log(e);
            });

        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function genPrivateMarketPlace(
    token: any,
    time: number | null,
    risk: number | null,
    _styles: any,
    _initialInvestment: any,
    _investmentPurpose: any,
    _accountType: any,
) {
    return async () => {
        if (
            time !== null &&
            risk !== null &&
            _styles !== null &&
            _initialInvestment !== null &&
            _investmentPurpose !== null &&
            _accountType !== null
        ) {
            try {
                let styles = JSON.stringify(_styles);
                let initialInvestment = JSON.stringify(_initialInvestment);
                let investmentPurpose = JSON.stringify(_investmentPurpose);
                let accountType = JSON.stringify(_accountType);

                const response = await fetch(
                    `${getApiUrl()}run3/pd.genPrivateMarketPlace/[${time},${risk / 10},${styles},${initialInvestment},${investmentPurpose},${accountType}]`,
                    {
                        mode: "cors",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": `Bearer ${token}`
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                dispatch(slice.actions.getPMPSuccess(result)); // Assuming slice is defined somewhere

            } catch (error) {
                console.log("error is " + error);
                dispatch(slice.actions.hasError(error));
            }
        }
    };
}


export function getBotConfigGroupingsPerf(token: any, Project: any, Portfolio: any, Type: any) {
    return async () => {
        let url = `${getApiUrl()}run3/pq.getPortGroupingsPerf/["${Project}","${Portfolio}","${Type}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getBotConfigGroupingPerfSuccess(result));
            });
        });
    };
}



export function getCustomPortfolioStyles(token: any, simType: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `${getApiUrl()}run3/pc.getCustomPortfolioStyles/["${simType}","${portfolioID}","${jobId}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getCustomPortfolioStyleSuccess(result));
            });
        });
    };
}


export function getCustomPortfolioGroupings(token: any, portfolioID: any, type: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `${getApiUrl()}run3/pc.getCustomPortfolioGroupings/["pmp", "${portfolioID}","${jobId}","${type}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getCustomPortfolioGroupingSuccess(result));
            });
        });
    };
}



export function filterPortfolios(filter: PortfoliosFilter) {
    return async () => {
        try {
            const response = await axios.post("/api/portfolios/filter", {filter});
            dispatch(slice.actions.filterPortfoliosSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPortfolio(id: string | undefined, token: string | null | undefined) {
    return async () => {
        try {   
            let jobId = await getJobId(token);
            await jobId && fetch(`${getApiUrl()}run3/pd.getPrivateMarketPlace/["${jobId}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    let portList = result.portList;
                    portList.map((port: any) => {
                        port.image = "port.png";
                        port.isStock = true;
                    });
                    let port = portList.find((p: any) => p.PortfolioID === id);
                    dispatch(slice.actions.getPortfolioSuccess(port));
                });
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getScoresAsync(id: string | undefined, token: string | null | undefined) {
    return async () => {
        try {
            let jobId = await getJobId(token);
            await jobId && fetch(`${getApiUrl()}run3/pd.getPrivateMarketPlace/["${jobId}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    let portList = result.portList;
                    portList?.map((port: any) => {
                        port.image = "port.png";
                        port.isStock = true;
                    });
                    let port = portList?.find((p: any) => p.PortfolioID === id);
                    dispatch(slice.actions.getScoresSuccess(port?.Scores.Components));
                    dispatch(slice.actions.getBenchmarkScoresSuccess(port?.Scores_BenchmarkEquities.Components));
                });
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}



export function getClientAlign(token: string | null | undefined) {
    return async () => {
        try {
            let jobId = await getJobId(token);
            await jobId && fetch(`${getApiUrl()}run3/pa.getClientAlign/["${jobId}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    dispatch(slice.actions.getAlignSuccess(result));
                });
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getRelatedPortfolios(id: string | undefined) {
    return async () => {
        try {
            const response = await axios.post("/api/portfolio/related", {id});
            dispatch(slice.actions.getRelatedPortfoliosSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPortfolioReviews() {
    return async () => {
        try {
            const response = await axios.get("/api/review/list");
            dispatch(slice.actions.getPortfolioReviewsSuccess(response.data.portfolioReviews));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAddresses() {
    return async () => {
        try {
            const response = await axios.get("/api/address/list");
            dispatch(slice.actions.getAddressesSuccess(response.data.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addAddress(address: Address) {
    return async () => {
        try {
            const response = await axios.post("/api/address/new", address);
            dispatch(slice.actions.addAddressSuccess(response.data.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editAddress(address: Address) {
    return async () => {
        try {
            const response = await axios.post("/api/address/edit", address);
            dispatch(slice.actions.editAddressSuccess(response.data.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
